import { Override } from "../types";
import {
  AbstractLinearTeam as AbstractLinearTeamDto,
  ClickUpIntegration as ClickUpIntegrationDto,
  JiraIntegration as JiraIntegrationDto,
  JiraIntegrationPatch as JiraIntegrationPatchDto,
  JiraProject as JiraProjectDto,
  LinearIntegration as LinearIntegrationDto,
  RequestParams,
  TodoistIntegration as TodoistIntegrationDto,
  TodoistProject as TodoistProjectDto,
} from "./client";
import { Domain } from "./types";

const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;

/* Todoist */

export type TodoistIntegration = Override<
  Required<TodoistIntegrationDto>,
  {
    availableProjects: TodoistProject[];
  }
>;

export type TodoistProject = Override<Required<TodoistProjectDto>, {}>;

/* Linear */

export type LinearTeam = Override<
  AbstractLinearTeamDto,
  {
    readonly id: string;
    readonly key: string;
    readonly name: string;
  }
>;

export type LinearIntegration = Override<
  LinearIntegrationDto,
  {
    readonly id: string;
    readonly availableTeams?: LinearTeam[];
  }
>;

export type ClickUpIntegration = Override<ClickUpIntegrationDto, {}>;

/* Jira */

export type JiraProject = Override<
  JiraProjectDto,
  {
    project_key: string;
    project_name: string;
  }
>;

export type JiraIntegration = Override<
  JiraIntegrationDto,
  {
    readonly id: string;
    available_projects?: JiraProject[];
  }
>;

export type JiraIntegrationPatch = Override<
  JiraIntegrationPatchDto,
  {
    installation: string;
    selected_project_keys: string[];
  }
>;

export class IntegrationsDomain extends Domain {
  /* Zoom */

  getZoomIntegration = this.manageErrors(this.api.integrations.getZoomIntegration);

  getZoomEnabled = this.manageErrors(() => {
    return this.api.integrations
      .getZoomIntegration()
      .then((zoom) => !!zoom && !!zoom.id)
      .catch(() => false);
  });

  /* Linear */

  getLinearIntegrations = this.manageErrors(this.api.linear.integrations3);

  patchLinearIntegration = (id: string, data: Partial<LinearIntegration>) => {
    return this.api.linear.patchIntegration2(id, data);
  };

  deleteLinearIntegration = (id: string) => {
    return this.api.linear.deleteIntegration3(id);
  };

  getLinearEnabled = this.manageErrors(() => {
    return this.api.linear
      .integrations3()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  /* Todoist */

  getTodoistIntegrations: (params?: RequestParams) => Promise<TodoistIntegration[]> = this.manageErrors(
    this.api.todoist.integrations4
  );

  patchTodoistIntegration = (id: number, data: Partial<TodoistIntegration>) => {
    return this.api.todoist.patchIntegration3(id, data);
  };

  deleteTodoistIntegration = (id: number) => {
    return this.api.todoist.deleteIntegration4(id);
  };

  getTodoistEnabled = this.manageErrors(() => {
    return this.api.todoist
      .integrations4()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  /* ClickUp */

  getClickUpIntegrations: (params?: RequestParams) => Promise<ClickUpIntegration[]> = this.manageErrors(
    this.api.clickup.integrations1
  );

  patchClickUpIntegration = (workspaceId: string, integration: Partial<ClickUpIntegration>) => {
    return this.api.clickup.patchIntegration(workspaceId, integration);
  };

  deleteClickUpIntegration = () => {
    return this.api.clickup.deleteIntegration1();
  };

  getClickUpEnabled = this.manageErrors(() => {
    return this.api.clickup
      .integrations1()
      .then((list) => !!list?.length && list.some((i) => !!i.workspace?.id))
      .catch(() => false);
  });

  /* Jira */

  getJiraIntegrations = this.manageErrors(this.api.jira.integrations2);

  patchJiraIntegration = (id: string, patch: JiraIntegrationPatch) => this.api.jira.patchIntegration1(id, patch);

  deleteJiraIntegration = (id: string) =>
    this.api.jira.deleteIntegration2(id).then((response: JiraIntegrationDto[]) => response as JiraIntegration[]);

  getJiraEnabled = () =>
    this.getJiraIntegrations()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRedirect(redirectUri: string, state: any, newTab?: boolean) {
    const url = new URL(`${OAUTH_BASE_URI}${redirectUri}`, window.location.href);
    url.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));

    if (newTab) {
      window.open(url.toString());
    } else {
      window.location.href = url.toString();
    }
  }
}
